import React, { useRef, useEffect, useMemo } from 'react';
// components
import TemplateYouTubeSlide from './slide';
// utils
import { getContentSectionsBySlide } from 'Util/Post';
//
import './styles.scss';

function TemplateYouTube({ post, renderIndex, onReady }) {
  const ref = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      onReady && onReady();
    }, 500);
  }, [post.id]);

  useEffect(() => {
    const { current } = ref;
    // set youtube colors
    if (current) {
      current.style.setProperty('--theme-color', post.themeColor);
      current.style.setProperty('--primary-color', post.primaryColor);
    }
  }, [post.themeColor]);

  const slides = useMemo(() => {
    return getContentSectionsBySlide(post.content);
  }, [post.content]);

  const renderIndexes = useMemo(() => {
    return renderIndex !== undefined
      ? [renderIndex]
      : slides.flat().map((item, index) => index);
  }, [renderIndex, slides]);

  return (
    <div ref={ref} className="template-youtube-stage">
      {renderIndexes.map((index) => {
        return (
          <TemplateYouTubeSlide
            key={index}
            slides={slides}
            renderIndex={index}
          />
        );
      })}
    </div>
  );
}

export default {
  id: 'youtube',
  title: 'YouTube',
  icon: 'youtube',
  color: 'text-red-500',
  renderToolbar: ({ post, onChange, renderTextButton, renderColorButton }) => {
    return (
      <>
        {renderColorButton({
          key: 'themeColor',
          icon: 'droplet',
          className: 'w-32',
        })}
        {renderTextButton({
          icon: 'zoom-in',
          text: '25%',
          onClick: () => {
            window.open(`/?/post-viewer/${post.id}`);
          },
        })}
      </>
    );
  },
  renderTemplate: (props) => {
    return <TemplateYouTube {...props} />;
  },
};
