import React, { useMemo } from 'react';
// templates
import TemplateDefault from './Default';
import Reel from './Reel';
import Newsletter from './Newsletter';
import Website from './Website';
import Quiz from './Quiz';
import YouTube from './YouTube';
import Carousell from './Carousell';

export const TEMPLATES = [Reel, Carousell, Newsletter, Website, Quiz, YouTube];

export default function (props) {
  const { post } = props;

  const Template = useMemo(() => {
    return TEMPLATES.find((template) => template.id === post.template);
  }, [post.template]);
  //
  return Template ? (
    Template.renderTemplate(props)
  ) : (
    <TemplateDefault {...props} />
  );
}
