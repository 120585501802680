import React, { useEffect, useState } from 'react';
// components
// templtes
import Template from 'Template';
//
import './styles.scss';

export default function ({ post }) {
  const [zoom, setZoom] = useState(1);

  //
  useEffect(() => {
    setZoom(post.template === 'youtube' ? 432 / 1920 : 432 / 1080);
  }, [post.template]);
  //
  return (
    <div className="editor-preview" style={{ zoom }}>
      <Template post={post} />
    </div>
  );
}
