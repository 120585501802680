import React, { useRef, useEffect, useMemo } from 'react';
// components
import MarkdownDisplay from 'Component/MarkdownDisplay';
// utils
import { getContentSlides, getContentSectionsBySlide } from 'Util/Post';
import { getRenderSectionsAndIndex } from './utils';
//
import './styles.scss';

function TemplateYouTubeSlide({ slides, renderIndex }) {
  const { sections, validIndex } = useMemo(() => {
    return getRenderSectionsAndIndex(slides, renderIndex);
  }, [slides, renderIndex]);

  return (
    <div className="template-slide template-youtube" data-index={renderIndex}>
      <div
        className="template-youtube-body"
        data-screenshot={`${renderIndex}.png`}
      >
        {sections.map((section, index) => {
          return (
            <div
              key={section}
              className="template-youtube-body-section"
              style={{ opacity: index > validIndex ? 0 : 1 }}
            >
              <MarkdownDisplay>{section}</MarkdownDisplay>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(TemplateYouTubeSlide);
